const numToWords = {
  500: "500",
  1000: "1 thousand",
  2000: "2 thousand",
  5000: "5 thousand",
  10000: "10 thousand",
  20000: "20 thousand",
  30000: "30 thousand",
  40000: "40 thousand",
  50000: "50 thousand",
  60000: "60 thousand",
  70000: "70 thousand",
  80000: "80 thousand",
  90000: "90 thousand",
  100000: "1 lakh",
};

function QuestionList({ questions, current }) {
  const isSafetyPoint = (value) => {
    return [10000, 50000].includes(value);
  };

  const getQuestionClass = (value, index) => {
    let classes = [];
    if (index < current) {
      classes.push("question-list__question--answered");
    } else if (index === current) {
      classes.push("question-list__question--current");
    } else if (isSafetyPoint(value)) {
      classes.push("question-list__question--milestone");
    } else {
      classes.push("question-list__question--unanswered");
    }
    return classes.join(" ");
  };

  const inWords = (num) => {
    return numToWords[num];
  };

  return (
    <table className="milestone_table">
      <tbody>
        <tr>
          <th>Q. No.</th>
          <th>Prize Money</th>
          <th>Prize in words</th>
        </tr>
        {questions
          .map((question, index) => (
            <tr
              key={index}
              className={
                getQuestionClass(question, index) + " question-list__question"
              }
            >
              <td>{index + 1}</td>
              <td>{question}</td>
              <td>{inWords(question)}</td>
            </tr>
          ))
          .slice(0)
          .reverse()}
      </tbody>
    </table>
  );
}

export default QuestionList;
